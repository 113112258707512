import { AnimatePresence } from 'framer-motion'
import Support from 'page/support'
import { cloneElement } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import './App.css'
import { ComingSoon, Home } from './page'

function App() {
  //? Router
  const routes = useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/support',
      element: <Support />
    },
    {
      path: '*',
      element: <ComingSoon />
    }
  ])
  const location = useLocation()

  return (
    <AnimatePresence mode='wait'>
      {cloneElement(routes, { key: location.pathname })}
    </AnimatePresence>
  )
}

export default App
