import { FixedHeader } from 'components/Header'

const PrivateLayout = ({ children }) => {
  return (
    <div className='relative min-h-screen bg-[#161c24]'>
      <FixedHeader />
      <div className='px-4 py-5 lg:px-8'>{children}</div>
    </div>
  )
}

export default PrivateLayout
