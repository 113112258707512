import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ data }) => {
  return (
    <div>
      <ul className='flex items-center gap-1'>
        {data.map((item, index) => (
          <BreadcrumbItem
            key={index}
            text={item.content}
            link={item.link}
            isLast={index === data.length - 1}
          />
        ))}
      </ul>
    </div>
  )
}

const BreadcrumbItem = ({ text, link, isLast }) => {
  return (
    <li className=''>
      <Link to={link} className='flex items-center gap-1 text-neutral-50'>
        <p className={`${isLast ? 'font-bold' : ''}`}>{text}</p>
        {!isLast ? <ChevronRightIcon className='w-3' /> : null}
      </Link>
    </li>
  )
}

export default Breadcrumb
