import { Tab } from '@headlessui/react'

const NavigationTab = ({ children, className, selected, onClick }) => {
  return (
    <Tab
      as='div'
      className={`w-full max-w-full grow cursor-pointer rounded !border-none px-2 py-2.5 
      text-center font-bold text-neutral-50 !shadow-none !outline-none transition-all
      duration-200 ease-linear
      ${selected ? 'bg-highlight text-darkblue' : ''} 
      ${className}`}
      onClick={onClick}
    >
      <div>{children}</div>
    </Tab>
  )
}

export default NavigationTab
