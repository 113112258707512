import { motion } from 'framer-motion'
import { PublicLayout } from '../components/Layout'
import Halo from '../components/Vanta/Halo'

const ComingSoon = () => {
  return (
    <motion.article
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{
        hidden: { opacity: 0 },
        visible: {
          opacity: 1
        }
      }}
    >
      <PublicLayout>
        <Halo>
          <div className='flex h-full w-full flex-col justify-center gap-4 p-4 lg:p-48'>
            <motion.div className='h-10'>
              <motion.img
                src={require('assets/img/rofi-logo.svg').default}
                alt='ROFI Games logo'
                className='h-full'
                initial='hidden'
                animate='visible'
                exit={{ opacity: 0 }}
                variants={{
                  hidden: {
                    opacity: 0,
                    scale: 1.5,
                    transition: { duration: 1 }
                  },
                  visible: {
                    transition: { delay: 1 },
                    scale: 1,
                    opacity: 1
                  }
                }}
              />
            </motion.div>
            <motion.h1
              className='text-[50px] font-bold leading-tight text-neutral-50 shadow-[#131a43] drop-shadow-lg lg:text-[60px]'
              initial='hidden'
              animate='visible'
              exit={{ opacity: 0, transition: { duration: 1 } }}
              variants={{
                hidden: { opacity: 0, y: 50, transition: { duration: 1 } },
                visible: { transition: { delay: 1.5 }, y: 0, opacity: 1 }
              }}
            >
              404 <br /> Not Found
            </motion.h1>
          </div>
        </Halo>
      </PublicLayout>
    </motion.article>
  )
}

export default ComingSoon
