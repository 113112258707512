import { Tab } from '@headlessui/react'
import Breadcrumb from 'components/Breadcrumb'
import PrivateLayout from 'components/Layout/PrivateLayout'
import { AccountPanel, NavigationTab, TabPanel } from 'components/Support'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const tabs = [
  {
    content: 'ACCOUNT',
    breadcrumbData: {
      content: 'ACCOUNT',
      link: '/support?opt=0'
    },
    panel: <AccountPanel />
  },
  {
    content: 'PAYMENT',
    breadcrumbData: {
      content: 'PAYMENT',
      link: '/support?opt=1'
    },
    panel: (
      <div className='py-10 lg:py-36'>
        <p className='text-center text-xl font-bold text-neutral-50'>
          Comming Soon
        </p>
      </div>
    )
  }
]

const breadcrumbInit = [{ content: 'SUPPORT', link: '/support' }]

const Support = () => {
  const navigate = useNavigate()
  const { hash, search } = useLocation()

  const [breadcrumbData, setBreadcrumbData] = useState(breadcrumbInit)

  const getOptTab = useMemo(() => {
    if (Number(queryString.parse(search)?.opt) !== 0)
      return Number(queryString.parse(search)?.opt) || -1
    return 0
  }, [search])

  const checkSelectedTab = useCallback(
    (tabId) => {
      const currentTabId = Number(queryString.parse(search)?.opt)
      return currentTabId === tabId
    },
    [search]
  )

  const chooseTab = useCallback(
    (tabId, breadcrumbItem) => {
      setBreadcrumbData([...breadcrumbInit, breadcrumbItem])
      navigate('/support?opt=' + tabId)
    },
    [navigate]
  )

  useEffect(() => {
    if (getOptTab <= 0 || getOptTab >= tabs.length) {
      setBreadcrumbData([...breadcrumbInit, tabs[0]?.breadcrumbData])
      navigate('/support?opt=0' + hash)
      return
    }
    setBreadcrumbData([...breadcrumbInit, tabs[getOptTab]?.breadcrumbData])
  }, [getOptTab, navigate, hash])

  return (
    <PrivateLayout>
      <div className='hidden py-10 text-center text-xl font-bold text-neutral-50 lg:py-36'></div>
      <div
        className='mx-auto max-w-[1000px] rounded bg-[#272d33] p-5 
        ring-2 ring-[#272d33] ring-offset-2 ring-offset-[#161c24]'
      >
        <Tab.Group
          defaultIndex={
            getOptTab <= 0 || getOptTab >= tabs.length ? 0 : getOptTab
          }
        >
          <Tab.List
            className='mx-auto flex w-full max-w-[500px] justify-center gap-3 
					  rounded bg-[#1C2229] p-2'
          >
            {tabs.map((tab, idx) => (
              <NavigationTab
                key={idx}
                selected={checkSelectedTab(idx)}
                onClick={() => chooseTab(idx, tab.breadcrumbData)}
              >
                {tab.content}
              </NavigationTab>
            ))}
          </Tab.List>

          <Tab.Panels
            className={`mt-2 space-y-4 ${
              getOptTab >= tabs.length || getOptTab < 0 ? 'hidden' : ''
            }`}
          >
            <Breadcrumb data={breadcrumbData} />

            {tabs.map((tab, idx) => (
              <TabPanel key={idx} className='mt-2'>
                {tab.panel}
              </TabPanel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </PrivateLayout>
  )
}

export default Support
