import { Disclosure, Transition } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useLocation, useNavigate } from 'react-router-dom'

import DeleteAccount from 'assets/img/deleteAccount.jpg'

const AccountPanel = () => {
  const navigate = useNavigate()
  const { pathname, search, hash } = useLocation()

  const data = [
    {
      title: 'Delete Account',
      content: (
        <>
          <p>To delete your account, please follow these below steps:</p>
          <p>
            - Access to{' '}
            <a
              className='text-highlight/80'
              href='https://app.rofi.games'
              target='_blank'
              rel='noreferrer'
            >
              https://app.rofi.games
            </a>{' '}
            and login with your ROFI ID
          </p>
          <p>
            - Go to your personal account{' '}
            <a
              className='text-highlight/80'
              href='https://app.rofi.games/#/account/profile'
              target='_blank'
              rel='noreferrer'
            >
              https://app.rofi.games/#/account/profile
            </a>{' '}
            then press the{' '}
            <span className='font-bold text-red-600'>
              "Delete this account"
            </span>{' '}
            button like the image below.
          </p>
          <div className='my-4 flex w-full justify-center'>
            <img
              src={DeleteAccount}
              alt='delete account'
              className='w-full max-w-md'
            />
          </div>
          <p>
            - After succesfully deleting your account, you will be logged out of
            the system.
          </p>
        </>
      )
    }
  ]

  const showData = (id) => {
    return `#${id}` === hash
  }

  const handleClickTitle = (id) => {
    if (!showData(id)) navigate(pathname + search + '#' + id)
    else navigate(pathname + search)
  }

  return (
    <div className='space-y-5 lg:space-y-10'>
      <div
        className='mx-auto h-auto w-full space-y-2 
        overflow-hidden rounded bg-[#1C2229] p-2 transition-all duration-200'
      >
        {data.length ? (
          data.map((item, idx) => (
            <Disclosure defaultOpen={showData(idx)} key={idx}>
              <Disclosure.Button
                className={`flex w-full justify-between 
                rounded py-2 px-2 font-bold  transition-all
                duration-150 ease-linear ${
                  showData(idx)
                    ? 'bg-highlight text-darkblue'
                    : 'bg-[#272d33] text-neutral-100'
                }`}
                onClick={() => handleClickTitle(idx)}
              >
                <span className='text-left'>{item.title}</span>
                <ChevronRightIcon
                  className='h-6 w-5 transition-all 
                  duration-150 ease-in ui-open:rotate-90 ui-open:transform'
                />
              </Disclosure.Button>
              <Transition
                show={showData(idx)}
                enter='transition duration-500 ease-out'
                enterFrom='transform opacity-0'
                enterTo='transform opacity-100'
                leave='transition duration-200 ease-out'
                leaveFrom='transform opacity-100'
                leaveTo='transform opacity-0'
              >
                <Disclosure.Panel className='p-2 pt-3 text-neutral-400'>
                  {item.content}
                </Disclosure.Panel>
              </Transition>
            </Disclosure>
          ))
        ) : (
          <div className='py-3 text-center text-neutral-300'>No data</div>
        )}
      </div>
    </div>
  )
}

export default AccountPanel
