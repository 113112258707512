import { Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

const FixedHeader = () => {
  const [searchDialog, setSearchDialog] = useState(false)
  return (
    <header className='w-full border-b border-b-[#212b36] bg-[#161c24]'>
      <div
        className='header sticky top-0 mx-auto flex max-w-[1000px] items-center justify-between 
        px-4 py-4 lg:px-0'
      >
        <div className='w-3/12'>
          <Link to='/'>
            <img
              src={require('assets/img/rofi-logo.svg').default}
              alt='ROFI Games logo'
              className='h-full'
              initial='hidden'
              animate='visible'
              exit={{ opacity: 0 }}
              variants={{
                hidden: {
                  opacity: 0,
                  scale: 0.1,
                  transition: { duration: 1 }
                },
                visible: {
                  transition: { delay: 1 },
                  scale: 1,
                  opacity: 1
                }
              }}
            />
          </Link>
        </div>

        <div className='hidden lg:block'>
          <div className='relative overflow-hidden rounded'>
            <div className='absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2'>
              <MagnifyingGlassIcon className='w-full text-neutral-300' />
            </div>
            <input
              type='text'
              placeholder='Search'
              className='bg-[#272d33] px-2 py-1 pl-9 text-neutral-100 placeholder:text-neutral-400
            focus:outline-none'
            />
          </div>
        </div>

        {/* <nav className='nav text-lg font-semibold'>
        <ul className='flex items-center'>
          <li className='active cursor-pointer border-b-2 border-green-500 border-opacity-0 p-4 duration-200 hover:border-opacity-100 hover:text-green-500'>
            <Link to='/'>Accueil</Link>
          </li>
        </ul>
      </nav> */}

        <div className='flex w-3/12 justify-end'>
          <div
            className='block h-5 w-5 cursor-pointer lg:hidden'
            onClick={() => setSearchDialog(true)}
          >
            <MagnifyingGlassIcon className='w-full text-neutral-300' />
          </div>
        </div>
      </div>
      <Transition appear show={searchDialog} as={Fragment}>
        <Dialog as='div' onClose={() => setSearchDialog(false)}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black/30' />
          </Transition.Child>

          <div className='fixed inset-0 flex items-center justify-center px-8'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-[500px]'>
                <Dialog.Title>Search</Dialog.Title>
                <div className='w-full'>
                  <input
                    type='text'
                    placeholder='Search'
                    className='w-full rounded bg-[#272d33] px-3 py-1
                    text-neutral-100 placeholder:text-neutral-400 focus:outline-none'
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </header>
  )
}

export default FixedHeader
