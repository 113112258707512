import { useEffect, useRef, useState } from 'react'
import HALO from 'vanta/dist/vanta.halo.min'

const Halo = ({ children }) => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const vantaRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        HALO({
          el: vantaRef.current,
          mouseControls: true,
          touchControls: false,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          amplitudeFactor: 0.0,
          size: 2.5,
          xOffset: 0.34,
          yOffset: 0.25
        })
      )
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <div ref={vantaRef} className='h-screen overflow-hidden'>
      {children}
    </div>
  )
}

export default Halo
